import React from 'react';

const SpotifyPlayer = ({ uri }) => {
  const src = `https://open.spotify.com/embed/${uri}`;
  return (
    <iframe
      src={src}
      title='Spotify Player'
      width="300"
      height="380"
      frameBorder="0"
      allowtransparency="true"
      allow="encrypted-media"
    ></iframe>
  );
};

export default SpotifyPlayer;