import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import './App.css';
import SpotifyPlayer from './SpotifyPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';


const Home = () => {
  useEffect(() => {
    document.title = "coltboy x jordies.vape.page";
  }, []);

  const navigate = useNavigate();

  const handleClickColtboy = (event) => {
    event.stopPropagation();
    navigate('/coltboy');
  };

  const handleClickJVP = () => {
    navigate('/jvp');
  };

  return (
    <div className="split-screen">

      <section className="top">
        <Link to="/coltboy" className="coltboy-side">
          <div className="content">
            <h1>Coltboy</h1>
            <p></p>
          </div>
        </Link>
      </section>

      <section className="bottom">
        <div className="wave" id="wave-container" onClick={handleClickJVP}>  
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" >
                  <path onClick={handleClickColtboy} d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>

        <Link to="/jvp" className="jvp-side jvp-style">
          <div className="content">
            <h1>jordies.vape.page</h1>
            <p></p>
          </div>
        </Link>
      </section>
      
    </div>
  );
};


const Coltboy = () => {
  return (
    <div className="full-page coltboy-style">
      <h1>coltboy</h1>
      <h4>Links</h4>
      <ul className="social-links social-links-coltboy">
        <li>
          <a href="https://open.spotify.com/artist/5YKsPinAhz05OpNf9QWAj0?si=nRnzKZMySM-tG-wR8Bwb7Q" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faSpotify} /> 
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@ColtboyOfficial" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
      </ul>
      <h4>Latest Release</h4>
      <SpotifyPlayer uri="track/0Mj3NGzxLRDtNeqjeI2keG" />
      <Link to="/jvp" className="toggle-button-bottom jvp-style"></Link>
      <Link to="/" className="home-button-bottom">
        <FontAwesomeIcon icon={faHome} />
      </Link> 
    </div>
  );
};

const JordiesVapePage = () => {
  return (
    <div className="full-page jvp-style">
      <h1>jordies.vape.page</h1>
      <ul className="social-links social-links-jvp">
        <li>
          <a href="https://www.instagram.com/jordies_vape_page/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} /> 
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@jordies.vape.page" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} /> 
          </a>
        </li>
      </ul>
      <Link to="/coltboy" className="toggle-button-top coltboy-style">Switch to coltboy</Link>
      <Link to="/" className="home-button-top">
        <FontAwesomeIcon icon={faHome} />
      </Link> 
    </div>
  );
};


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coltboy" element={<Coltboy />} />
        <Route path="/jvp" element={<JordiesVapePage />} />
      </Routes>
    </Router>
  );
}

export default App;
